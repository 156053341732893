import * as React from 'react'
import { useRef, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { popupManager, popupDataManager } from '@/recoil/popup'
import { marketCodeManager, marketCodesListState } from '@/recoil/marketCode'
import { MAP_POPUP_ID } from '@/components/Popup/config'
import StoreLocator from '@/components/StoreLocator'
import { isMobileState } from '@/recoil/layout'
import { useLocation } from '@reach/router'

import Layout from '@/layouts/Default'

import { useWatchSections } from '@/hook/index'

import ProductHero from '@/components/ProductHero'
import Taste from '@/components/Taste'
import BlockSlider from '@/components/Block/TextSlider'
import BlockTextImage from '@/components/Block/TextImage'
import BlockTextImages from '@/components/Block/TextImages'
import BlockVideoSlider from '@/components/Block/Sliders/VideoSlider'
import BlockQuote from '@/components/Block/Quote'
import Button from '@/components/Button'
import Link from '@/components/Link'
import SectionsNav from '@/components/SectionsNav'
import WinesSlider from '@/components/WinesSlider'
import TextImage from '@/components/Block/TextImage'
import { PRODUCT_FEATURE, VIEW_ITEM, ADD_TO_CART } from '@/utils/gtmEvents'
import isPOSInCurrentMarket from '@/utils/isPOSInCurrentMarket'
import resolveFileURL from '@/utils/resolveFileURL'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { isEcommerceState } from '@/recoil/isEcommerce'

// Fake data temp
// import dataWineSlider from '@/pages/index.data.json'

const ProductPage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const marketCode = useRecoilValue(marketCodeManager)
  const isEcommerce = useRecoilValue(isEcommerceState)
  const document = data.drupal.nodeById
  const vintages = data.drupal.otherVintagesQuery.entities
  const products = data.drupal.otherProductsQuery.entities
  const isFullTemplate = !document.fieldWineProductTemplate
  const enjoyWithBloc = data.drupal.nodeById.fieldWineProdEnjoyWithBlock?.entity.fieldWineProductEnjoyWith.entity
  const enjoyWithBlocText = enjoyWithBloc?.fieldStandardContentBlock[0]?.entity
  const enjoyWithBlocCarousel = enjoyWithBloc?.fieldStandardImageCarrousel?.entity.fieldCarrouselImageItem
  const wineMakingBloc = data.drupal.nodeById?.fieldWineProdWinemakingBlock?.entity?.fieldWineProductWinemaking?.entity
  const wineMakingBlocText = wineMakingBloc?.fieldStandardTextBlock.entity
  const wineMakingBlocImages = wineMakingBloc?.fieldStandard2imagesBlock.entity
  const videosBloc = data.drupal.nodeById.fieldWineProdVideoCarrousel?.entity.fieldVideoCarrousel.entity.fieldVideoCarrouselItems
  const localisationBloc = data.drupal.nodeById.fieldWineProductVineyard?.entity.fieldVineyardsBlock.entity
  const quoteBloc = data.drupal.nodeById.fieldWineProductQuote?.entity
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)
  const wineTaste = useRef()
  const enjoyWith = useRef()
  const vinification = useRef()
  const ourVideos = useRef()
  const localisation = useRef()
  const marketCodesList = useRecoilValue(marketCodesListState)
  const location = useLocation()
  const gtm = (action, product) => {
    window.dataLayer && window.dataLayer.push({
      event: PRODUCT_FEATURE,
      item_name: product,
      interaction_type: action
    })
  }
  // .map(image => image.entity.url)

  useEffect(() => {
    if (window.scrollTo) { window.scrollTo(0, 0) }

    gtmItem()
  }, [])

  const gtmItem = () => {
    const item_name = document.fieldWineProductVintage ? `${document.fieldWineProductVintage} ${document.fieldCategoryProductName}` : document.fieldCategoryProductName
    const item_id = `${document.fieldCapacitySku.length > 0 ? document.fieldCapacitySku[0].entity.fieldWinedirectSku : item_name}`

    window.dataLayer && window.dataLayer.push({
      ecommerce: null
    })

    window.dataLayer && window.dataLayer.push({
      event: VIEW_ITEM,
      ecommerce: {
        items: [{
          item_id,
          item_price: parseFloat(document.fieldWineProductPrice),
          item_name,
          item_brand: 'Cloudy Bay',
          item_category: 'Wine',
          item_variant: data.drupal.nodeById.fieldCategoryProductName
        }]
      }
    })
  }

  const gtmRetailer = (retailer, formData = {}) => {
    console.log(formData)
    const item_name = document.fieldWineProductVintage ? `${document.fieldWineProductVintage} ${document.fieldCategoryProductName}` : document.fieldCategoryProductName
    const item_id = `${document.fieldCapacitySku.length > 0 ? document.fieldCapacitySku[0].entity.fieldWinedirectSku : item_name}`

    window.dataLayer && window.dataLayer.push({
      ecommerce: null
    })

    window.dataLayer && window.dataLayer.push({
      event: ADD_TO_CART,
      retailer_name: retailer,
      ecommerce: {
        items: [{
          item_id,
          item_name,
          item_price: parseFloat(formData.price),
          item_brand: 'Cloudy Bay',
          item_category: 'Wine',
          item_variant: data.drupal.nodeById.fieldCategoryProductName,
          quantity: formData.quantity || 1
        }]
      }
    })
  }

  const [Section, sections] = useWatchSections(
    [wineTaste, enjoyWith, vinification, ourVideos, localisation],
    {
      offsetTop: 132 /* header + sectionsNav todo set dynamic value */
    }
  )

  const handleMapActionClick = () => {
    gtm('View Map', document.title)
    const popupData = localisationBloc.fieldStandard2imagesBlock.entity.fieldRightImage
    setPopupData({ title: popupData.title, subtitle: 'Map', image: { url: popupData.url, alt: popupData.alt } })
    setPopup(MAP_POPUP_ID)
  }

  const productsSliderItems = products.map((category) => (
    {
      img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow.url,
      art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
      name: category.name,
      price: category.fieldWineCategoryFromPrice,
      colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
      colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
      title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
      text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
      path: category.fieldWineCatPrimaryVintage.entity.path.alias
    }
  ))

  const tagsList = [
    document.fieldWineProduct1stKeyTaste.entity.fieldTastingNoteIcon,
    document.fieldWineProduct2ndKeyTaste.entity.fieldTastingNoteIcon,
    document.fieldWineProduct3rdKeyTaste.entity.fieldTastingNoteIcon
  ]

  const tastesList = [
    { min: 'Fruity', max: 'Savoury', value: document.fieldWineProdFruitySavoury },
    { min: 'Structural', max: 'Soft', value: document.fieldWineProdStructuralSoft },
    { min: 'Sweet', max: 'Dry', value: document.fieldWineProdSweetDry },
    { min: 'Vibrant', max: 'Mellow', value: document.fieldWineProdVibrantMellow },
    { min: 'Bold', max: 'Elegant', value: document.fieldWineProdBoldElegant }
  ]

  const vintagesList = vintages
    .sort((a, b) => b.fieldWineProductVintage - a.fieldWineProductVintage)
    .map((vintage) => {
      const eretailers = vintage.fieldWproductEretailerSelect
        .filter(r => r.entity.fieldWineProductERetailer && r.entity.fieldWineProductProductLink)
        .filter((pos) => isPOSInCurrentMarket(pos?.entity?.fieldWineProductERetailer?.entity, marketCode, marketCodesList))

      return {
        label: `${vintage.fieldWineProductVintage} vintage`,
        url: `/${marketCode}${vintage.path.alias}`,
        isCurrent: vintage.nid === document.nid,
        isShoppable: (
          !isEcommerce && !!eretailers.length
        ) || (
          isEcommerce && (vintage.fieldCapacitySku.length > 0 || !!eretailers.length)
        )
      }
    })

  const sliderImages = _ => {
    return Array.from(enjoyWithBlocCarousel, item => item.entity.fieldImageItem)
  }

  const sliderVideos = _ => {
    const videosList = []

    for (const video in videosBloc) {
      const v = videosBloc[video].entity
      videosList.push({
        thumb: v.fieldCoverImageVideoItem.url,
        thumbAlt: v.fieldCoverImageVideoItem.alt,
        source: v.fieldVideoLinkVideoItem.url.path,
        text: v.fieldDescriptionVideoItemn,
        title: v.fieldTitleVideoItem,
        category: ''
      })
    }

    return videosList
  }
  const wineMakingButtons = _ => {
    const buttons = []

    if (wineMakingBlocText?.fieldStandardContentDownload && wineMakingBlocText?.fieldStandardCta) {
      buttons.push(<Button target='_blank' key={ 1 } to={ wineMakingBlocText?.fieldStandardContentDownload?.entity?.url }>{ wineMakingBlocText?.fieldStandardCta?.title }
      </Button>)
    }
    return buttons
  }

  const retailers = document.fieldWproductEretailerSelect
    .filter(r => r.entity.fieldWineProductERetailer && r.entity.fieldWineProductProductLink)
    .filter((pos) => isPOSInCurrentMarket(pos?.entity?.fieldWineProductERetailer?.entity, marketCode, marketCodesList))
    .sort((x, y) => { return y?.entity?.fieldWineProductERetailer?.entity?.sticky - x.entity?.fieldWineProductERetailer?.entity?.sticky }) // sticky true goes first

  const getCapacities = (data) => {
    return data.map(c => {
      return c.entity.fieldCapacity.entity
    })
  }

  const richResultSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: document.title,
    image: document.fieldWineProductImageGallery.entity.fieldWineProductImageItem.map(image => image.entity.url),
    description: document.fieldWineProductDescription,
    brand: {
      '@type': 'Brand',
      name: 'Cloudy Bay'
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: 'USD',
      price: document.fieldWineProductPrice
    }
  }

  return (
    <Layout entityBundle='wine_product' path={ pagePath } requireAgeGate={ requireAgeGate } plainHeader pageType='Product Page' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{document.fieldWineProductMetaTags.entity.fieldMetaTitle}</title>
        <meta name='description' content={ document.fieldWineProductMetaTags.entity.fieldMetaDescription } />
        <meta property='og:title' content={ document.fieldWineProductMetaTags.entity.fieldMetaTitle } />
        <meta property='og:description' content={ document.fieldWineProductMetaTags.entity.fieldMetaDescription } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='product' />
        {document.fieldWineProductMetaTags.entity.fieldMetaOpenGraphImage && <meta property='og:image' content={ document.fieldWineProductMetaTags.entity.fieldMetaOpenGraphImage?.url } />}
        {document.fieldWineProductMetaTags.entity.fieldMetaOpenGraphImage && <meta property='og:image:alt' content={ document.fieldWineProductMetaTags.entity.fieldMetaOpenGraphImage?.alt } /> }
        <script type='application/ld+json'>
          {JSON.stringify(richResultSchema)}
        </script>
      </Helmet>
      {ProductHero && <ProductHero
        title={ document.title }
        description={ document.fieldWineProductDescription }
        images={ document.fieldWineProductImageGallery.entity.fieldWineProductImageItem }
        recommendedTemperature={ document.fieldWineProductConsumption }
        cellaringPotential={ document.fieldWineProductCellaring }
        bottlingDate={ document.fieldWineProductBottling }
        alcoholPercentage={ document.fieldWineProductAlcohol }
        vintages={ vintagesList }
        tags={ tagsList }
        gtm={ gtm }
        gtmRetailer={ gtmRetailer }
        bgColor={ document.fieldWineProductBackcolor }
        price={ document.fieldWineProductPrice }
        capacities={ getCapacities(document.fieldCapacitySku) }
        sku={ document.fieldCapacitySku }
        retailers={ retailers }
        pelorusURL={ document.fieldWineProductPelorusLink?.uri }
      />}

      {isFullTemplate && (
        <>
          <SectionsNav wineName={ document.title } gtm={ gtm } sections={ sections } />
          <Section name='tasting note' id='wine-tast' ref={ wineTaste }>
            {document.fieldWineProductTasteTitle && <Taste
              title={ document.fieldWineProductTasteTitle }
              tasteTitle={ document.fieldWineProdTasteDescTitle }
              text={ document.fieldWineProductTasteDesc }
              tags={ tagsList }
              tastes={ tastesList }
              buttonPdf={ document.fieldWineProductTastingNotes ? resolveAssetURL(document.fieldWineProductTastingNotes.entity.url) : '' }
              wineName={ document.title }
              gtm={ gtm } />
            }
          </Section>
          {enjoyWithBloc && <Section name='enjoy with' id='enjoy-with' ref={ enjoyWith }>
            <BlockSlider
              id="textcercle1"
              subtitle={ enjoyWithBlocText.fieldStandardSubtitle }
              title={ enjoyWithBlocText.fieldStandardTitle }
              text={ enjoyWithBlocText.fieldStandardDescription }
              actions={ [
                <Button onClick={ () => gtm('More inspiration', document.title) } key={ 1 } to={ enjoyWithBlocText.fieldStandardCta.url.path }>{ enjoyWithBlocText.fieldStandardCta.title }</Button>
              ] }
              images={ sliderImages() }
            />
                            </Section>}
          {wineMakingBloc && <Section name='vinification' id='vinification' ref={ vinification }>
            <BlockTextImages
              subtitle={ wineMakingBlocText.fieldStandardSubtitle }
              title={ wineMakingBlocText.fieldStandardTitle }
              text={ wineMakingBlocText.fieldStandardDescription }
              actions={ wineMakingButtons() }
              images={ [
                wineMakingBlocImages.fieldLeftImage.url,
                wineMakingBlocImages.fieldRightImage.url
              ] }
              alt={ [
                wineMakingBlocImages.fieldLeftImage.alt,
                wineMakingBlocImages.fieldRightImage.alt
              ] }
            />
                             </Section>}
          {videosBloc && <Section name='our videos' id='our-videos' ref={ ourVideos }>
            <BlockVideoSlider
              title='Our videos'
              medias={ sliderVideos() }
              gtm={ gtm }
            />
                         </Section>}
          <Section name='localisation' id='localisation' ref={ localisation }>
            {localisationBloc &&
              <BlockTextImage
                subtitle={ localisationBloc.fieldStandardTextBlock.entity.fieldStandardSubtitle }
                title={ localisationBloc.fieldStandardTextBlock.entity.fieldStandardTitle }
                text={ localisationBloc.fieldStandardTextBlock.entity.fieldStandardDescription }
                actions={ [
                  <Button onClick={ handleMapActionClick } key={ 2 }>{ localisationBloc.fieldStandardTextBlock.entity.fieldStandardCta.title }</Button>,
                  <Button onClick={ () => gtm(localisationBloc.fieldStandardTextBlock.entity.fieldStandardContentCta2Link?.title, document.title) } key={ 1 } to={ localisationBloc.fieldStandardTextBlock.entity.fieldStandardContentCta2Link?.url.path } type='underline'>{ localisationBloc.fieldStandardTextBlock.entity.fieldStandardContentCta2Link?.title }</Button>
                ] }
                image={ localisationBloc.fieldStandard2imagesBlock.entity.fieldLeftImage.url }
                alt={ localisationBloc.fieldStandard2imagesBlock.entity.fieldLeftImage.alt }
              />
            }
            {quoteBloc &&
              <BlockQuote
                img={ isMobile ? quoteBloc.fieldStandardQuoteAuthorImgm?.url : quoteBloc.fieldStandardQuoteAuthorImgd?.url }
                author={ quoteBloc.fieldStandardQuoteAuthorName }
                content={ quoteBloc.fieldStandardQuoteAuthorText }
              />
            }
          </Section>
        </>
      )}

      {!isFullTemplate && (
        <TextImage
          title={ document.fieldWineProductTasteTitle }
          subtitle={ document.fieldWineProductTastesubtitle }
          actions={
            document.fieldWineProductTastingNotes
              ? [<Button key={ 1 } to={ resolveAssetURL(document.fieldWineProductTastingNotes.entity.url) } target='_blank;'>See Tasting note</Button>]
              : []
          }
          text={ document.fieldWineProductTasteDesc }
          image={ document.fieldWineProductTastingImage?.url }
          imageOrder='right'
        />
      )}

      <WinesSlider
        id="textcercle1"
        title={ data.drupal.homeQuery.fieldHomeProductBlockTitle }
        items={ productsSliderItems }
      />
      <div id='storeLocatorProductPage' />
      <StoreLocator pageType='Product Page' />
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($nid: String!, $tid: String!) {
    drupal {
      nodeById(id: $nid) {
        title
        nid
        path {
          alias
        }
        ... on Drupal_NodeWineProduct {
          fieldWineProductMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                fieldMetaTitle
                fieldMetaDescription
                fieldMetaOpenGraphImage {
                  url
                  alt
                }
              }
            }
          }
          fieldWproductEretailerSelect {
            entity {
              ... on Drupal_ParagraphERetailerLink {
                fieldWineProductProductLink {
                  uri
                }
                fieldWineProductERetailer {
                  entity {
                    ... on Drupal_NodePosItem {
                      fieldPosName
                      fieldPosAddress {
                        countryCode
                      }
                      fieldPosExternalUrl {
                        uri
                      }
                      sticky
                    }
                  }
                }
              }
            }
          }
          fieldWineProductPelorusLink {
            uri
          }
          fieldWineProdTasteDescTitle
          fieldWineProductDescription
          fieldWineProductImageGallery {
            entity {
              ... on Drupal_ParagraphWineProductImageGallery {
                fieldWineProductImageItem {
                  entity {
                    ... on Drupal_ParagraphWineProductImageGalleryItem {
                      fieldWineProductImage {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineProductPrice
          fieldWproductBottleCapacity {
            entity {
              ... on Drupal_TaxonomyTermBottleCapacities {
                name
                uuid
                tid
              }
            }
          }
          fieldWineProductVintage
          fieldCapacitySku {
            entity {
              ... on Drupal_ParagraphWineProductSku {
                fieldCapacity {
                  entity {
                    ... on Drupal_TaxonomyTermBottleCapacities {
                      name
                      uuid
                      tid
                    }
                  }
                }
                fieldWinedirectSku
              }
            }
          }
          fieldWineProductTemplate
          fieldWineProductConsumption
          fieldWineProductCellaring
          fieldWineProductBottling
          fieldWineProductAlcohol
          fieldWineProductBackcolor
          fieldWineProdFruitySavoury
          fieldWineProdStructuralSoft
          fieldWineProdSweetDry
          fieldWineProdVibrantMellow
          fieldWineProdBoldElegant
          fieldWineProductTasteTitle
          fieldWineProductTasteDesc
          fieldWineProductTastesubtitle
          fieldWineProductTastingNotes {
            entity {
              url
              uri {
                url
              }
            }
          }
          fieldWineProductTastingImage {
            url
            alt
            title
          }
          fieldWineProduct1stKeyTaste {
            entity {
              ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                fieldTastingNoteIcon {
                  url
                  alt
                  title
                }
              }
            }
          }
          fieldWineProduct2ndKeyTaste {
            entity {
              ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                fieldTastingNoteIcon {
                  url
                  alt
                  title
                }
              }
            }
          }
          fieldWineProduct3rdKeyTaste {
            entity {
              ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                fieldTastingNoteIcon {
                  url
                  alt
                  title
                }
              }
            }
          }
          fieldWineProdEnjoyWithBlock {
            targetId
            entity {
              ... on Drupal_NodeEnjoyWith {
                title
                fieldWineProductEnjoyWith {
                  entity {
                    ... on Drupal_ParagraphStandardContentAndImageCarro {
                      id
                      fieldStandardContentBlock {
                        entity {
                          id
                          entityLabel
                          ... on Drupal_ParagraphStandardContentBlock {
                            id
                            fieldStandardTitle
                            fieldStandardSubtitle
                            fieldStandardDescription
                            fieldStandardCta {
                              title
                              url {
                                path
                              }
                            }
                          }
                        }
                      }
                      fieldStandardImageCarrousel {
                        entity {
                          id
                          ... on Drupal_ParagraphStandardImageCarrousel {
                            id
                            fieldCarrouselImageItem {
                              entity {
                                id
                                ... on Drupal_ParagraphCarrouselImageItem {
                                  id
                                  fieldImageItem {
                                    alt
                                    height
                                    title
                                    url
                                    width
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineProdWinemakingBlock {
            entity {
              ... on Drupal_NodeWinemaking {
                nid
                fieldWineProductWinemaking {
                  entity {
                    ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                      id
                      fieldStandardTextBlock {
                        entity {
                          ... on Drupal_ParagraphStandardContentBlock {
                            id
                            fieldStandardTitle
                            fieldStandardSubtitle
                            fieldStandardDescription
                            fieldStandardCta {
                              url {
                                path
                              }
                              title
                            }
                            fieldStandardContentDownload {
                              entity {
                                url
                              }
                            }
                          }
                        }
                      }
                      fieldStandard2imagesBlock {
                        entity {
                          ... on Drupal_ParagraphStandardTwoImagesBlock {
                            id
                            fieldLeftImage {
                              alt
                              height
                              width
                              title
                              url
                            }
                            fieldRightImage {
                              alt
                              height
                              title
                              url
                              width
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineProdVideoCarrousel {
            entity {
              ... on Drupal_NodeVideoCarrousel {
                nid
                fieldVideoCarrousel {
                  entity {
                    ... on Drupal_ParagraphStandardVideoCarrousel {
                      id
                      fieldVideoCarrouselItems {
                        entity {
                          ... on Drupal_ParagraphStandardVideoCarrouselItem {
                            id
                            fieldVideoLinkVideoItem {
                              url {
                                path
                              }
                              title
                              options
                              attribute(key: "")
                            }
                            fieldCoverImageVideoItem {
                              alt
                              width
                              url
                              height
                            }
                            fieldDescriptionVideoItem
                            fieldTitleVideoItem
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineProductVineyard {
            entity {
              ... on Drupal_NodeVineyardsAndTerroir {
                nid
                fieldVineyardsBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                      id
                      fieldStandardTextBlock {
                        entity {
                          ... on Drupal_ParagraphStandardContentBlock {
                            id
                            fieldStandardTitle
                            fieldStandardSubtitle
                            fieldStandardDescription
                            fieldStandardCta {
                              url {
                                path
                              }
                              title
                            }
                            fieldStandardContentCta2Link {
                              url {
                                path
                              }
                              title
                            }
                          }
                        }
                      }
                      fieldStandard2imagesBlock {
                        entity {
                          ... on Drupal_ParagraphStandardTwoImagesBlock {
                            id
                            fieldLeftImage {
                              alt
                              height
                              width
                              title
                              url
                            }
                            fieldRightImage {
                              alt
                              height
                              title
                              url
                              width
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineProductQuote {
            entity {
              ... on Drupal_ParagraphStandardQuoteWithAuthor {
                fieldStandardQuoteAuthorName
                fieldStandardQuoteAuthorText
                fieldStandardQuoteAuthorImgm {
                  url
                  alt
                }
                fieldStandardQuoteAuthorImgd {
                  alt
                  url
                }
              }
            }
          }
          fieldCategoryProductName
        }
      }
      homeQuery: nodeById(id: "14") {
        ... on Drupal_NodeHomePage {
          nid
          fieldHomeProductBlockTitle
        }
      }
      otherProductsQuery: taxonomyTermQuery(
        limit: 1000,
        offset: 0,
        sort: [{field: "weight", direction: ASC}],
        filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}, {operator: NOT_EQUAL, field: "tid", value: [$tid]}]}
      ) {
        entities {
          ... on Drupal_TaxonomyTermWineCategory {
            name
            fieldWineCategorySlugUrl
            fieldWineCategoryFromPrice
            fieldWineCatPrimaryVintage {
              entity {
                ...on Drupal_NodeWineProduct {
                  nid
                  path {
                    alias
                  }
                  title
                  fieldProductDiscoverColorCta
                  fieldWineProductBackcolor
                  fieldWineProductBackillustrat {
                    url
                  }
                  fieldWineProductShortDesc
                  fieldWineProductName
                  fieldCategoryProductName
                  fieldWineProductDiscoverCta {
                    title
                  }
                  fieldWineProdPackshotShadow {
                    url
                  }
                }
              }
            }
          }
        }
      }
      otherVintagesQuery: nodeQuery(
        filter: {conditions: [{operator: EQUAL, field: "status", value: "1"}, {operator: EQUAL, field: "type", value: "wine_product"}, {operator: EQUAL, field: "field_wine_product_category.entity.tid", value: [$tid]}]},
        limit: 1000
      ) {
        entities {
          ... on Drupal_NodeWineProduct {
            nid
            path {
              alias
            }
            fieldWineProductVintage
            fieldWineProductName
            fieldWproductEretailerSelect {
              entity {
                ... on Drupal_ParagraphERetailerLink {
                  fieldWineProductProductLink {
                    uri
                  }
                  fieldWineProductERetailer {
                    entity {
                      ... on Drupal_NodePosItem {
                        fieldPosName
                        fieldPosAddress {
                          countryCode
                        }
                        fieldPosExternalUrl {
                          uri
                        }
                        sticky
                      }
                    }
                  }
                }
              }
            }
            fieldCapacitySku {
              entity {
                ... on Drupal_ParagraphWineProductSku {
                  fieldWinedirectSku
                }
              }
            }
            fieldWineProductCategory {
              entity {
                ... on Drupal_TaxonomyTermWineCategory {
                  fieldWineCategorySlugUrl
                  fieldWineCatPrimaryVintage {
                    entity {
                      ...on Drupal_NodeWineProduct {
                        nid
                        path {
                          alias
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `

export default ProductPage
